/* eslint-disable no-undef */
//show/hide border properties
export default (el) => {
  const ui = document.getElementById('props');
  if (el === null || el.node.nodeName === 'foreignObject') {
    ui.classList.add('hide-vis');
  } else if (el.node.nodeName !== 'foreignObject') {
    ui.classList.remove('hide-vis');
  }
};
