/* eslint-disable no-undef */
import ftAdd from './ftAdd';
import targetEl from './objects/targetEl';

///////////////////paste image or txt from clipboard ctrl v//////////////////////
document.onpaste = function (pasteEvent) {
  // consider the first item (can be easily extended for multiple items)
  const item = pasteEvent.clipboardData;
  const snap = Snap('#stg');
  if (item.types.length > 0) {
    if (item.items[0].type.indexOf('image') === 0) {
      const file = item.items[0].getAsFile();
      const reader = new FileReader();

      if (file.size > 4194304) {
        alert('File is too big! Max file size allowed is 4MB');
        return;
      }

      // convert image file to base64 stringF
      reader.addEventListener(
        'load',
        () => {
          //get image dimensions and add to stage
          const image = new Image();
          image.src = reader.result;
          image.onload = function () {
            const bf = document.querySelector('foreignObject') || null; //bf = before
            let el;
            if (bf != null) {
              el = snap
                .image(
                  reader.result,
                  window.scrollX + (window.innerWidth / 2 - image.width / 2),
                  window.scrollY + (window.innerHeight / 2 - image.height / 2),
                  image.width,
                  image.height
                )
                .attr({ class: 'els' })
                .insertBefore(bf);
            } else {
              el = snap
                .image(
                  reader.result,
                  window.scrollX + (window.innerWidth / 2 - image.width / 2),
                  window.scrollY + (window.innerHeight / 2 - image.height / 2),
                  image.width,
                  image.height
                )
                .attr({ class: 'els' });
            }
            ftAdd(el);
          };
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (item.items[0].type.indexOf('text') === 0) {
      ////console.log(item.getData('text'));
      if (targetEl.mceStatus === false) {
        // eslint-disable-next-line no-alert
        alert(
          'Your clipboard contains text. First create a text block first then paste(Ctrl+V) into the text block'
        );
      }
    }
  }
};
/***************/
