/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import ui from '../objects/uiProps';
import { startDrawing } from '../drawing';
import textNode from '../textNodeDraw';
import { allDrag, allUndrag } from '../drag';
import shape from '../objects/shapes';

// eslint-disable-next-line no-use-before-define, no-undef
const snap = Snap('#stg');

///////////////left bar tools
export default ui.buttons.forEach((el) => {
  const { id } = el;

  el.addEventListener('click', () => {
    if (id === 'in-grid') {
      const bgImg = new URL(`../../images/grid-bg-598.jpg`, import.meta.url);
      // eslint-disable-next-line no-unused-expressions, prettier/prettier
      el.classList.toggle('active_btn') ? snap.node.style.backgroundImage = `url('${bgImg.href}')` : snap.node.style.backgroundImage = '';
      return;
    }
    //remove then add active class
    highliteActiveButton(el);
    //remove existing events on stage
    snap.unmousedown(startDrawing).unmousedown(textNode);
    //remove drag from all el's
    allUndrag();
    //set object value
    shape.type = id;
    //add relevant event to stage
    if (shape.drawTools.includes(id)) {
      snap.node.style.cursor = 'crosshair';
      snap.mousedown(startDrawing, shape.type);
    } else if (id === 'in-txt') {
      snap.node.style.cursor = 'text';
      snap.mousedown(textNode);
    } else if (id === 'in-move') {
      snap.node.style.cursor = 'default';
    }
    //add drag back to all el's
    allDrag();
  });
});

//sidebar - remove class from active button
const highliteActiveButton = (el) => {
  //remove all active classes
  ui.buttons.forEach((el) => {
    if (el.classList.contains('active_btn')) el.classList.remove('active_btn');
  });
  //add active class to active button
  el.classList.add('active_btn');
};
