/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import targetEl from '../objects/targetEl';
import shape from '../objects/shapes';
import uiProps from '../objects/uiProps';
import { addMrkr, removeMrkr } from './marker';

/*G: bottom bar***********************/

//color swatches
document.getElementById('swatches').addEventListener('click', (e) => {
  const color = e.target.dataset.elColor;
  const el = targetEl.el[1].node || targetEl.node; //clicked on || drawing
  const { nodeName, attributes } = el;

  if (el != null) {
    //fill attr for shapes
    if (
      shape.color === 'fill' &&
      (nodeName === 'circle' || nodeName === 'rect' || nodeName === 'ellipse')
    ) {
      attributes.fill.value = color;
    } else if (nodeName === 'polyline') {
      attributes.stroke.value = color;
      //stroke attr
    } else {
      //this sets the el for first draw or clicked on
      attributes.stroke.value = color;
      const marker = el.style;

      //marker end
      if (marker.markerEnd) {
        const markerId = marker.markerEnd.slice(6, -2);
        // eslint-disable-next-line prettier/prettier
        document.getElementById(markerId).firstChild.setAttribute('fill', color);
      }
      //marker start
      if (marker.markerStart) {
        const markerId = marker.markerStart.slice(6, -2);
        document
          .getElementById(markerId)
          .firstChild.setAttribute('fill', color);
      }
    }
  } else if (el === null) {
    alert('No item selected.');
  }
});

/* property checkboxes*/
document.getElementById('props').addEventListener('click', (e) => {
  if (typeof e.target.id === 'string') {
    //Destructure e, targetEl for clarity
    // eslint-disable-next-line prettier/prettier
    const { target, target: { id } } = e;
    const { el, name } = targetEl;
    const elShape = el;
    const elLine = el[1] || el;
    const line0 = uiProps.line[0];
    const line1 = uiProps.line[1];
    const color0 = uiProps.color[0];
    const color1 = uiProps.color[1];

    //note it is better to use vars as above, to avoid lookup
    /*const box = {
        target,
        id,
        elShape: el,
        elLine: el[1] || el,
        line: uiProps.line,
        color:  uiProps.color,
        name
      };*/
    switch (id) {
      case 'start':
        if (name === 'line' || name === 'path' || name === 'polyline') {
          target.classList.toggle('prop-sel')
            ? addMrkr(elLine, id)
            : removeMrkr(elLine, id);
        }
        break;
      case 'end':
        if (name === 'line' || name === 'path' || name === 'polyline') {
          target.classList.toggle('prop-sel')
            ? addMrkr(elLine, id)
            : removeMrkr(elLine, id);
        }
        break;
      case 'dashed':
        name === 'line'
          ? elLine.attr({ 'stroke-dasharray': '5 4' })
          : elShape.attr({ 'stroke-dasharray': '5 4' });
        el.data('lineStyle', 'dashed');
        line1.classList.add('prop-sel');
        line0.classList.remove('prop-sel');
        break;
      case 'solid':
        name === 'line'
          ? elLine.attr({ 'stroke-dasharray': '0 0' })
          : elShape.attr({ 'stroke-dasharray': '0 0' });
        el.data('lineStyle', 'solid');
        line0.classList.add('prop-sel');
        line1.classList.remove('prop-sel');
        break;
      case 'fill':
        shape.color = 'fill';
        color1.classList.add('prop-sel');
        color0.classList.remove('prop-sel');
        break;
      default:
        shape.color = 'stroke';
        color0.classList.add('prop-sel');
        color1.classList.remove('prop-sel');
        break;
    }
  }
});
