//import SVGImage from "./objects/stage";
// eslint-disable-next-line no-undef
const snap = Snap('#stg');
//add drag to all els
export const allDrag = () => {
  const allG = snap.selectAll('.els');
  allG.forEach((el) => {
    const { freeTransform } = el;
    if (el.type /*!== "foreignObject"*/)
      freeTransform?.showHandles().hideHandles({ undrag: false });
  });
};

//remove drag from all els
export const allUndrag = () => {
  const allG = snap.selectAll('.els');
  allG.forEach((el) => {
    const { freeTransform } = el;
    freeTransform?.hideHandles({ undrag: true });
  });
};
