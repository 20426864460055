/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
import elStyle from '../objects/elStyles';
import targetEl from '../objects/targetEl';

export const addMrkr = function (el, id) {
  // eslint-disable-next-line no-undef
  const snap = Snap('#stg');
  let objDrawStart;
  let objDrawEnd;

  switch (elStyle.marker) {
    case 'default':
      if (id === 'start') {
        const markerPathStart = snap
          .polyline([0, 10, 20, 20, 15, 10, 20, 0, 0, 10])
          .attr({ 'vector-effect': 'non-scaling-stroke', 'stroke-width': 2 });
        objDrawStart = markerPathStart.marker(0, 0, 6, 6, 13, 10).attr({
          viewBox: '0 0 20 20',
          orient: 'auto',
          preserveAspectRatio: 'xMaxYMax meet',
          markerUnits: 'strokeWidth'
        });
        targetEl.el.data('startM', 1);
      } else {
        const markerPathEnd = snap
          .polyline([0, 0, 20, 10, 0, 20, 5, 10, 0, 0])
          .attr({ 'vector-effect': 'non-scaling-stroke', 'stroke-width': 2 });
        objDrawEnd = markerPathEnd.marker(0, 0, 6, 6, 13, 10).attr({
          viewBox: '0 0 20 20',
          orient: 'auto',
          preserveAspectRatio: 'xMaxYMax meet',
          markerUnits: 'strokeWidth'
        });
        targetEl.el.data('endM', 1);
      }
      break;
    case 'pen':
      const markerPath = snap
        .path(
          'M 6.6826111,5.7121595 C 6.2241951,5.9884487 5.797983,6.2859113 5.3395669,6.5622016 3.8847619,7.5120693 2.3712342,8.4810953 0.91642888,9.4309634 0.49021828,9.7284267 0.14551898,11.234872 0.77442018,10.839593 2.2027061,9.9300603 3.657511,8.9801929 5.0857969,8.0706588 5.7999392,7.6158911 6.5140838,7.1611246 7.1960207,6.6851821 7.8514406,6.2495751 8.5276924,5.7121263 9.2380624,5.5437251 9.6813284,5.4307832 10.107499,4.1512136 9.7002054,3.9989615 6.7582539,2.9311992 3.9223769,1.7021041 1.1338508,0.33083264 0.75308088,0.13824952 0.10907878,1.6789806 0.58077748,1.8735788 2.1625823,2.6247516 3.7443878,3.3759258 5.37923,4.0464314 6.211114,4.2804287 8.3510834,4.8787817 6.6826111,5.7121595 Z'
        )
        .attr({ 'vector-effect': 'non-scaling-stroke' });
      markerPath.marker(0, 0, 4, 4, 7.5, 4.9).attr({
        viewBox: '0 0 10 10',
        orient: 'auto',
        preserveAspectRatio: 'xMaxYMax meet',
        markerUnits: 'strokeWidth'
      });
      break;
  }

  if (el.type === 'g') {
    //if imported project
    el = el.children()[1];
  }

  // eslint-disable-next-line no-unused-expressions
  id === 'start'
    ? el.attr({ markerStart: objDrawStart })
    : el.attr({ markerEnd: objDrawEnd });
};

export const removeMrkr = (el, name) => {
  if (name === 'start') {
    el.node.style.removeProperty('marker-start');
    targetEl.el.data('startM', 0);
  } else {
    el.node.style.removeProperty('marker-end');
    targetEl.el.data('endM', 0);
  }
};
