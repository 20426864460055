/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
import { modalStatus } from './modal';
import ftAdd from './ftAdd';

//DRAG DROP UPLOAD
const dzPath = document.getElementById('dz');
const box = document.querySelector('.box');
dzPath.addEventListener('dragover', (e) => {
  box.style.outline = '2px dashed rgb(178, 222, 39)';
  e.preventDefault();
});

dzPath.addEventListener('dragleave', () => {
  box.style.outline = '2px dashed #92b0b3';
});

dzPath.addEventListener('drop', (e) => {
  box.style.background = 'rgba(34, 47, 62,0.9)';
  e.preventDefault();
  modalStatus('modalImportSVG', 'none', 'none');
  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0, len = e.dataTransfer.items.length; i < len; i++) {
      // If dropped items aren't files, reject them
      if (e.dataTransfer.items[i].kind === 'file') {
        const reader = new FileReader();
        const file = e.dataTransfer.items[i].getAsFile();
        if (file.size > 4194304) {
          alert('File is too big! Max file size allowed is 4MB');
          return;
        }
        // convert image file to base64 string
        reader.addEventListener(
          'load',
          () => {
            //get image dimensions and add to stage
            const snap = Snap('#stg');
            const image = new Image();
            image.src = reader.result;
            image.onload = function () {
              const bf = document.querySelector('foreignObject'); //bf = before
              let el;
              if (bf != null) {
                el = snap
                  .image(
                    reader.result,
                    window.scrollX + (window.innerWidth / 2 - image.width / 2),
                    // eslint-disable-next-line prettier/prettier
                    window.scrollY + (window.innerHeight / 2 - image.height / 2),
                    image.width,
                    image.height
                  )
                  .attr({ class: 'els' })
                  .insertBefore(bf);
              } else {
                el = snap
                  .image(
                    reader.result,
                    window.scrollX + (window.innerWidth / 2 - image.width / 2),
                    // eslint-disable-next-line prettier/prettier
                    window.scrollY + (window.innerHeight / 2 - image.height / 2),
                    image.width,
                    image.height
                  )
                  .attr({ class: 'els' });
              }
              ftAdd(el);
            };
          },
          false
        );

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0, len = e.dataTransfer.files.length; i < len; i++) {
      //console.log('... file[' + i + '].name = ' + e.dataTransfer.files[i].name);
    }
  }
});

//UPLOAD BUTTON
document.getElementById('upl').addEventListener('change', () => {
  const file = document.getElementById('upl').files[0];
  const reader = new FileReader();

  if (file.size > 4194304) {
    alert('File is too big! Max file size allowed is 4MB');
    return;
  }

  reader.addEventListener(
    'load',
    () => {
      //get image dimensions and add to stage
      const snap = Snap('#stg');
      const image = new Image();
      image.src = reader.result;
      image.onload = function () {
        //console.log(image.height);
        //console.log(image.width);
        //NB NB NB NB still do - get center of stage to paste image
        //let el = SVGImage.image(reader.result, 5637, 5202, image.width, image.height) //Paper.image(src, x, y, width, height)

        const bf = document.querySelector('foreignObject') || null; //bf = before
        let el;
        //drawObj = shape.getDrawObject(shape.type,x,y);
        if (bf != null) {
          el = snap
            .image(
              reader.result,
              window.scrollX + (window.innerWidth / 2 - image.width / 2),
              window.scrollY + (window.innerHeight / 2 - image.height / 2),
              image.width,
              image.height
            )
            .attr({ class: 'els' })
            .insertBefore(bf);
        } else {
          el = snap
            .image(
              reader.result,
              window.scrollX + (window.innerWidth / 2 - image.width / 2),
              window.scrollY + (window.innerHeight / 2 - image.height / 2),
              image.width,
              image.height
            )
            .attr({ class: 'els' });
        }
        ftAdd(el);
      };
    },
    false
  );

  if (file) {
    reader.readAsDataURL(file);
  }
});
