/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
const modalContainer = document.getElementById('modal-container');
const modalIntroContent = document.getElementById('modal-intro-content');
const modalImportSVG = document.getElementById('modal-svg-import');
const modalImageUpload = document.getElementById('modal-image-upload');

//SVG Upload Modal
document.getElementById('in-svgupload').onclick = function () {
  modalStatus('modalImportSVG', 'block', 'block');
};

//Image Upload Modal
document.getElementById('in-upload').onclick = function () {
  modalStatus('modalImageUpload', 'block', 'block');
};

// When the user clicks on <span> (x), close the modal
document.querySelectorAll('.close').forEach((el) => {
  el.onclick = function () {
    if (modalIntroContent) modalIntroContent.style.display = 'none';
    if (modalImportSVG) modalImportSVG.style.display = 'none';
    if (modalImageUpload) modalImageUpload.style.display = 'none';
    modalContainer.style.display = 'none';
  };
});

//change this to
// eslint-disable-next-line import/prefer-default-export
export const modalStatus = (modal, visContent, visConatiner) => {
  switch (modal) {
    case 'modalImageUpload':
      modalImageUpload.style.display = visContent;
      break;
    case 'modalImportSVG':
      modalImportSVG.style.display = visContent;
      break;
    default:
    // code block
  }
  modalContainer.style.display = visConatiner;
};
