/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
import targetEl from './objects/targetEl';
import ui from './objects/uiProps';
import { activeRemove } from './activeRemove';
import borderProperties from './borderProperties';
import configTinymce from './configTinymce';
/*add all mouse events to el - this function is called when the el is created
 *- when el's are drawn
 *- imported to stage for images and text
 *************************************** */

export default function (el) {
  //add freeTransform
  if (el.node.nodeName === 'image') {
    Snap('#stg').freeTransform(el, {
      size: 3,
      rotate: true,
      keepRatio: true,
      attrs: { fill: '#ffffff', stroke: '#000000' }
    });
  } else if (el.node.id === 'ruler') {
    Snap('#stg').freeTransform(el, {
      size: 3,
      rotate: false,
      attrs: { fill: '#ffffff', stroke: '#000000' },
      range: { rotate: [0, 0], scale: [0, 0] }
    });
  } else {
    Snap('#stg').freeTransform(el, {
      size: 3,
      rotate: true,
      attrs: { fill: '#ffffff', stroke: '#000000' }
    });
  }

  //this file is only run when the object is created and all these vars are utilized in the click and doubleclick events.
  const { freeTransform, node } = el;
  const mceClass = node.classList[1];
  Object.assign(targetEl, {
    el,
    node,
    foNode: node,
    mceClassId: node.children[0]?.classList[1],
    mceClass
  });

  //tinymce init
  if (mceClass === 'txt') {
    freeTransform?.hideHandles({ undrag: false });
    el.data('ftStatus', 0);
    tinymce.init(configTinymce());
  } else {
    targetEl.mathConst = el; //set here for first draw
    //show handles for shapes
    freeTransform?.showHandles();
    el.data('ftStatus', 1);
    //ft.hideHandles({undrag: true});
    Snap('#stg').node.style.cursor = 'default';
  }

  //get clicked el and remove outilne and any active handles
  el.click((e) => {
    /*this property is for matrix math. it is set only once on click of element.
       so each time a new el is selected it is updated and stored until another el is clciked.*/
    targetEl.mathConst = el; //set here for first draw
    activeRemove(e); //removes outline on other el's
    e.stopPropagation(); //stop bubbling to stage

    // eslint-disable-next-line no-shadow
    const { freeTransform, node } = el;
    Object.assign(targetEl, {
      el,
      node,
      name: e.target.nodeName
    });
    //hide/show border properties
    borderProperties(el);
    const { classList } = node;
    //if in-text is active i.e creating a new text node
    if (el.data('textActive') === 1) {
      freeTransform?.hideHandles({ undrag: false });
      classList.add('txt_outlined'); //add outline
      el.data('txtOutline', 1);
    } else {
      //all elements except text
      // eslint-disable-next-line no-lonely-if
      if (classList[1] !== 'txt') {
        if (el.data('ftStatus')) {
          if (el.id !== targetEl.el.id) {
            freeTransform?.hideHandles({ undrag: false });
            el.data('ftStatus', 0);
          }
        } else {
          freeTransform?.showHandles();
          el.data('ftStatus', 1);
        }
        //set ui properties for clicked element
        const { line, startM, endM } = ui;

        // eslint-disable-next-line prettier/prettier
        el.data('startM') ? startM.classList.add('prop-sel') : startM.classList.remove('prop-sel');
        // eslint-disable-next-line prettier/prettier
        el.data('endM') ? endM.classList.add('prop-sel') : endM.classList.remove('prop-sel');

        if (el.data('lineStyle') === 'solid') {
          line[0].classList.add('prop-sel');
          line[1].classList.remove('prop-sel');
        } else {
          line[1].classList.add('prop-sel');
          line[0].classList.remove('prop-sel');
        }
      } else {
        //textoutline is added to text foreignobject only
        node.classList.add('txt_outlined'); //add outline
        el.data('txtOutline', 1);
      }
    }
  });

  //double click is for text el only
  el.dblclick((e) => {
    e.stopPropagation(); //stop bubbling to stage

    const { freeTransform, node } = el;
    Object.assign(targetEl, {
      node,
      name: e.target.nodeName
    });

    //if txt then add tinymce init event
    if (node.classList[1] === 'txt') {
      /*
        fr freeTransform is the 3rd mousedown event attached to the element.
        hideHandles() removes the mousedown event
        undrag: true removes the drag  --adds back in blur
        */
      el.data('ftStatus', 0).attr({ cursor: 'auto' });
      freeTransform.hideHandles({ undrag: true });

      //set tinymce config values
      const nodeChildren = node.children[0];
      Object.assign(targetEl, {
        el,
        node: nodeChildren,
        foNode: node,
        mceClassId: nodeChildren.classList[1]
      });

      //attach tinymce
      tinymce.init(configTinymce()); //from config selector: '.txt_ed/ed....',
    }
  });

  //hide handles when svg uploaded
  if (targetEl.SVGUploadFlag === 1) {
    freeTransform?.hideHandles({ undrag: false });
    targetEl.SVGUploadFlag = 0;
  }
}
