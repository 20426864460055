/* eslint-disable no-undef */
export default {
  line: document.querySelector('.prop-bdr .fl').children,
  color: document.querySelector('.prop-style .fl').children,
  startM: document.getElementById('start'),
  endM: document.getElementById('end'),
  buttons: Array.from(document.querySelector('.tt').getElementsByTagName('p')),
  cMenu: document.querySelector('.context_menu').style,
  zoom: 3
};
