/* eslint-disable no-undef */
/************************************
 * General Notes
 * When an HTML document is loaded into a web browser, it becomes the document object.
 * The document object (HTMLDocument) is a property of the window object.
 *
 * Snap("#stg")
 * Snap("#stg") - First lets create our drawing surface out of existing SVG element
 * click and mousedown events for drawing are attached to the drawing surface Snap("#stg")
 *
 * Element Object
 * elemet objects are added to document object using functions:
 * - startDrawing   ->   updateDrawing    ->   stopDrawing
 * these are called using the click, dblclick and mousedown events which are attached to the stage
 * In the HTML DOM, the Element object represents an HTML element, like P, DIV, A, TABLE, or any other HTML element.
 *
 *
 * freeTransform plugin
 * freeTransform property is added to each element object
 * this library adds drag, resize and rotate to the elements
 * each el has 4 events attached - 1.click, 2.dblclick, 3.mousedown, 4.another mousedown event for freeTransform
 *
 */

import '../modules/stageClear';
import '../modules/context_menu';
import '../modules/modal';
import '../modules/ui/barLeft';
import '../modules/objects/uiProps';
import '../modules/activeRemove';
import '../modules/objects/targetEl';
import '../modules/objects/shapes';
import '../modules/objects/elStyles';
import '../modules/drawing';
import '../modules/zoom';

// Import TinyMCE
import './tinymce/tinymce';
import './tinymce/themes/silver/theme';
import './tinymce/icons/icons/icons';
import './tinymce/skins/ui/oxide-dark/skin.min.css';
import './tinymce/skins/ui/oxide-dark/content.inline.min.css';
import './tinymce/plugins/link/plugin';
import './tinymce/plugins/lists/plugin';
import './tinymce/plugins/autolink/plugin';
import './tinymce/plugins/advlist/plugin';
import './tinymce/plugins/table/plugin';
import './tinymce/plugins/autoresize/plugin';
import './tinymce/plugins/paste/plugin';
import './tinymce/plugins/charmap/plugin';
import './tinymce/plugins/contextmenu/plugin';

import '../modules/clone';
import '../modules/ui/barBottom';
import '../modules/deleteNode';
import '../modules/pageReload';
import '../modules/clipboard';
import '../modules/fullScreen';
//import '../modules/download';
import '../modules/uploadImage';
import '../modules/importSVG';
import stageCenter from '../modules/stageCenter';

stageCenter();

//redirect if not domain
//if(window.location.href != 'https://www.waenote.com/' && window.location.href != 'https://waenote.com/') window.location.href = 'https://www.waenote.com/forbidden.html';

/* download SVG *******************/
document.getElementById('dwnld').addEventListener('click', () => {
  //saveModal.style.display = "block";
  const snap = Snap('#stg');
  const serializer = new XMLSerializer();
  if (document.querySelector('.ftaxisline')) snap.node.lastChild.remove();

  //if any active text blocks then remove
  if (document.querySelector('.act')) {
    const els = snap.selectAll('.els');

    // eslint-disable-next-line prettier/prettier
    els.forEach(el => {
      if (el.className === 'act') el.classList.remove('act', 'txt_outlined');
    });

    /* iterators/generators require regenerator-runtime, which is too heavyweight for this guide to allow them. Separately, loops should be avoided in favor of array iterations */
    // for (const el of els) {
    //   el.className === 'act' ? el.classList.remove('act', 'txt_outlined') : null;
    // }
  }

  //remove rulerStage
  document.getElementById('rulerStage')?.remove();

  const str = serializer.serializeToString(snap.node);
  //console.log(str);

  svgExport.downloadSvg(
    str, // SVG DOM Element object to be exported. Alternatively, a string of the serialized SVG can be passed
    'skratche', // chart title: file name of exported image
    { useCSS: 'false' } // defualt is true whish looks in external css stylesheet
  );
});
