/* eslint-disable no-undef */
/* toggle full screen *********************/
document.getElementById('ut-fs').addEventListener('click', () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
});
/***********************************/
