/* eslint-disable no-use-before-define */
/* eslint-disable no-case-declarations */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
//import SVGImage from "./objects/stage";
import targetEl from './objects/targetEl';
import elStyle from './objects/elStyles';
import shape from './objects/shapes';
import ftAdd from './ftAdd';
import { allDrag, allUndrag } from './drag';
import ui from './objects/uiProps';

let drawObj = null;
const bufferSize = 20;
let strPath = '';
let buffer = []; // Contains the last positions of the mouse cursor
const snap = Snap('#stg');
const { type } = shape;
///////////////////drawing shapes
// eslint-disable-next-line no-shadow
export const getDrawObject = function (shape, x, y) {
  let svg = null;
  switch (shape) {
    case 'in-rect':
      svg = snap.rect(x, y, 0, 0);
      break;
    case 'in-line':
      svg = snap.line(x, y, x, y);
      break;
    case 'in-ellipse':
      svg = snap.ellipse(x, y, 0, 0);
      break;
    case 'in-poly':
      svg = snap.polyline(x, y).attr({ class: 'els' });
      break;
    case 'in-line-curve-l':
    case 'in-line-curve-r':
      svg = snap.path();
      break;
    default: //curley brackets in-curl
      svg = snap.path().attr({ class: 'els' });
  }
  return svg.attr({
    stroke: '#222F3E',
    fill: 'transparent',
    'stroke-width': elStyle.lineWidth,
    'vector-effect': 'non-scaling-stroke',
    'stroke-linecap': 'round'
  });
};

export const startDrawing = function (event, x, y) {
  //cannot use 'this' instead of shape -  because it is called in a  event so the 'this' defaults to the element
  
  shape.sPos = { x1: x, y1: y }; //save start positions into obj for use in update drawing
  const allowedTypes = new Set(['in-ellipse', 'in-rect', 'in-circle']);
  if (allowedTypes.has(type)) {
    const bf = document.querySelector('foreignObject') || null; //bf = before
    if (bf != null) {
      drawObj = getDrawObject(type, x, y).insertBefore(bf);
    } else {
      drawObj = getDrawObject(type, x, y);
    }
    targetEl.name = drawObj.type;
    targetEl.node = drawObj.node;
  } else {
    drawObj = getDrawObject(shape.type, x, y);
    targetEl.name = drawObj.type;
    targetEl.node = drawObj.node;
  }
  if (shape.type === 'in-poly') {
    let { points } = shape;
    points = drawObj.attr('points');
    buffer = [];
    const pt = { x: points[0] * 1, y: points[1] * 1 };
    strPath = `${pt.x} ${pt.y} `;
    appendToBuffer(pt);
  } else shape.points = drawObj.attr();
  //remove drag events
  allUndrag();
  //when start drwaing add the update and stopdrawing  events
  snap.mousemove(updateDrawing).mouseup(stopDrawing); //this adds the mouseup event
};

export const updateDrawing = function (event, x, y) {
  /*x1,y1 is starting static mouse position
  x,y is current (dynamic) mouse position*/
  const { x1 } = shape.sPos;
  const { y1 } = shape.sPos;

  switch (shape.type) {
    case 'in-rect':
      drawObj.attr({
        x: Math.min(x1, x), //min() takes lowest value
        y: Math.min(y1, y),
        width: Math.abs(x1 - x),
        height: Math.abs(y1 - y)
      });
      break;
    case 'in-line':
      drawObj.attr({ x2: x, y2: y });
      break;
    case 'in-circle':
      //from the mousedown x and y subtract the current mouse position and calculate radius based on greater of x or y
      const a = Math.abs(x1 - x);
      const b = Math.abs(y1 - y);
      if (a > b) drawObj.attr({ r: a });
      else drawObj.attr({ r: b });
      break;
    case 'in-ellipse':
      // eslint-disable-next-line prettier/prettier
      let { points: { rx, ry } } = shape;
      rx = Math.abs(x1 - x);
      ry = Math.abs(y1 - y);
      drawObj.attr({ rx, ry }); //{ rx: rx, ry: ry }
      break;
    case 'in-poly':
      appendToBuffer({ x, y });
      updateSvgPath();
      break;
    case 'in-line-curve-l':
    case 'in-line-curve-r':
      const initialX = x1; // Assuming x1 is your initial x value
      const initialY = y1; // Assuming y1 is your initial y value

      // Calculate the change in x and y
      const deltaX = Math.abs(x - initialX);
      const deltaY = Math.abs(y - initialY);

      // Calculate the total number of increments for every 10-point change
      const incrementsX = Math.floor(deltaX / 10);
      const incrementsY = Math.floor(deltaY / 10);

      // Update the offset based on the number of increments
      const offset = 30 + (incrementsX + incrementsY) * 2;

      // Determine which increment is greater and replace the lesser one
      if (incrementsX > incrementsY) {
        // Replace y with its starting point y1
        y = y1;
      } else if (incrementsY > incrementsX) {
        // Replace x with its starting point x1
        x = x1;
      }

      // Calculate midpoints
      const mpx = (x + x1) * 0.5;
      const mpy = (y + y1) * 0.5;

      // Angle of perpendicular to line:
      const theta = Math.atan2(y - y1, x - x1) - Math.PI / 2;

      // Location of control point:
      const c1x = mpx + offset * Math.cos(theta);
      const c1y = mpy + offset * Math.sin(theta);

      drawObj.attr({
        d: `M${x1} ${y1} Q ${c1x} ${c1y} ${x} ${y}`
      });
      break;
    default:
      //curly bracket
      const w = 50;
      const q = 0.6;
      //Calculate unit vector
      let dx = x1 - x;
      let dy = y1 - y;
      const len = Math.sqrt(dx * dx + dy * dy);
      dx /= len;
      dy /= len;

      //Calculate Control Points of path,
      const qx1 = x1 + q * w * dy;
      const qy1 = y1 - q * w * dx;
      const qx2 = x1 - 0.25 * len * dx + (1 - q) * w * dy;
      const qy2 = y1 - 0.25 * len * dy - (1 - q) * w * dx;
      const tx1 = x1 - 0.5 * len * dx + w * dy;
      const ty1 = y1 - 0.5 * len * dy - w * dx;
      const qx3 = x + q * w * dy;
      const qy3 = y - q * w * dx;
      const qx4 = x1 - 0.75 * len * dx + (1 - q) * w * dy;
      const qy4 = y1 - 0.75 * len * dy - (1 - q) * w * dx;

      drawObj.attr({
        d: `M ${x1} ${y1} Q ${qx1} ${qy1} ${qx2} ${qy2} T ${tx1} ${ty1} M ${x} ${y} Q ${qx3} ${qy3} ${qx4} ${qy4} T ${tx1} ${ty1}`
      });
  }
};

export const stopDrawing = function (event, x, y) {
  //this is just removing the mouseup and mousemove startdrawing events
  snap
    .unmousemove(updateDrawing)
    .unmouseup(stopDrawing)
    .unmousedown(startDrawing);
  targetEl.el = drawObj;
  if (shape.type !== 'in-curl') {
    //////clone the el for better cklicking
    targetEl.handle = targetEl.el.clone().attr({
      stroke: 'transparent',
      fill: 'transparent',
      'stroke-width': 10
    });
    targetEl.groupedEl = snap.g(targetEl.handle, targetEl.el).attr({
      class: 'els',
      stroke: '#000000'
    });
    ftAdd(targetEl.groupedEl);
    ui.startM.classList.remove('prop-sel');
    ui.endM.classList.remove('prop-sel');
    targetEl.groupedEl.data('new', 1);
    targetEl.el = targetEl.groupedEl; //need to set this to use in addMrkr
  } else {
    ftAdd(targetEl.el); //add freetransform
    targetEl.el.data('new', 1);
  }
  targetEl.el.data('lineStyle', 'solid');
  ui.line[0].classList.add('prop-sel');
  ui.line[1].classList.remove('prop-sel');
  //add drag back to all
  allDrag();
  targetEl.el.freeTransform.showHandles();
};

////////////////////////////////////////////////////////////////////////////

const appendToBuffer = function (pt) {
  buffer.push(pt);
  while (buffer.length > bufferSize) {
    buffer.shift();
  }
};

// Calculate the average point, starting at offset in the buffer
const getAveragePoint = function (offset) {
  const len = buffer.length;
  if (len % 2 === 1 || len >= bufferSize) {
    let totalX = 0;
    let totalY = 0;
    let pt;
    let count = 0;
    for (let i = offset; i < len; i++) {
      count++;
      pt = buffer[i];
      totalX += pt.x;
      totalY += pt.y;
    }
    return {
      x: totalX / count,
      y: totalY / count
    };
  }
  return null;
};

const updateSvgPath = function () {
  let pt = getAveragePoint(0);

  if (pt) {
    // Get the smoothed part of the path that will not change
    strPath += `${pt.x} ${pt.y} `;

    // Get the last part of the path (close to the current mouse position)
    // This part will change if the mouse moves again
    let tmpPath = '';
    for (let offset = 2, len = buffer.length; offset < len; offset += 2) {
      pt = getAveragePoint(offset);
      tmpPath += `${pt.x} ${pt.y} `;
    }
    // Set the complete current path coordinates
    drawObj.attr({ points: strPath + tmpPath });
  }
};
