/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
//import SVGImage from "./objects/stage";
import textNode from './textNodeDraw';
import { startDrawing } from './drawing';
import targetEl from './objects/targetEl';
import ftAdd from './ftAdd';

/* clone tool*********/
export default document.getElementById('c').addEventListener('click', () => {
  const snap = Snap('#stg');
  // eslint-disable-next-line prettier/prettier
  const { el, name, node: { classList } } = targetEl;
  //remove existing events first
  snap.unmousedown(startDrawing).unmousedown(textNode);
  snap.node.style.cursor = 'default';
  //check if targetEl.el is not null i.e is an element selected
  if (el === null) {
    alert('No element selected! Select an element to copy');
  } else {
    let newObjX = null;
    const allowedNames = new Set(['rect', 'image', 'ellipse', 'line']);
    if (allowedNames.has(name) || classList[1] === 'txt') {
      console.log(el);
      const newObj = el.clone();
      console.log(newObj);
      if (newObj.node.classList[1] === 'txt') {
        const newNode = newObj.node.children[0];
        newNode.classList.replace(
          newNode.classList[1],
          `txt_ed${Math.floor(Math.random() * 100000000)}`
        );
        newNode.id = 'txt_bx'; //this is for focus
      }
      //const newObjNN = newObj.node.nodeName;
      if (name === 'rect' || name === 'image' || classList[1] === 'txt') {
        //this is the original node name
        //need to add the ftAdd() to the new object after editing position so save the obj to a new var
        newObjX = newObj.attr({
          x: '+=50',
          y: '+=50'
        });
      } else if (name === 'ellipse') {
        newObjX = newObj.attr({
          cx: '+=50',
          cy: '+=50'
        });
      } else if (name === 'line') {
        //const el = newObj.node.childNodes;
        newObj.children().forEach((el) => {
          newObjX = el.attr({
            x1: '+=50',
            x2: '+=50',
            y1: '+=50',
            y2: '+=50'
          });
        });
        newObjX = newObj;
      }
      ftAdd(newObjX);
    } else {
      alert('Copy/Paste failed!');
    }
  }
});
