/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
//import SVGImage from "../modules/objects/stage";

/* clear stage */
export default function clearSVG() {
  const snap = Snap('#stg');
  if (this !== window) {
    if (confirm('Confirm clear all content?')) snap.clear();
  } else {
    snap.clear();
    snap.remove();
  }
}

document.getElementById('clear').addEventListener('click', clearSVG);
