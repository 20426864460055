/* eslint-disable no-shadow */
/* eslint-disable no-undef */
import stageCenter from './stageCenter';
//import  SVGImage from '../modules/objects/stage'; //this is creating error becuase of reassign on line 35
import { activeRemove } from './activeRemove';
import ftAdd from './ftAdd';
import targetEl from './objects/targetEl';
import { modalStatus } from './modal';
import waitingGif from './waitingGif';
/*************************SVG upload***********************/
const uploadModal = document.getElementById('modal-svg-import');
const snap = Snap('#stg');
//A: DRAG & DROP
document.getElementById('dz_svg').addEventListener('dragover', (e) => {
  uploadModal.getElementsByClassName('has-advanced-upload')[0].style.outline =
    '2px dashed rgb(178, 222, 39)';
  // Prevent default behavior (Prevent file from being opened)
  e.preventDefault();
});

document.getElementById('dz_svg').addEventListener('dragleave', () => {
  uploadModal.getElementsByClassName('has-advanced-upload')[0].style.outline =
    '2px dashed #92b0b3';
});

document.getElementById('dz_svg').addEventListener('drop', (e) => {
  e.preventDefault();
  modalStatus('modalImportSVG', 'none', 'block');
  waitingGif('block');
  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    if (e.dataTransfer.items[0].kind === 'file') {
      snap.clear();
      snap.remove();
      const reader = new FileReader();
      const file = e.dataTransfer.items[0].getAsFile();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.addEventListener('load', () => {
        targetEl.SVGUploadFlag = 1;
        Snap.load(reader.result, (frag) => {
          Snap('#svg-container').append(frag); //stage is <svg
          const snap = Snap('#stg');
          stageCenter();
          //add outlineremove listener for stage
          snap.node.addEventListener('click', activeRemove);
          //attach snap obj to all elements
          const allG = snap.selectAll('.els');
          allG.forEach((el) => {
            ftAdd(el);
            if (el.freeTransform)
              el.freeTransform.hideHandles({ undrag: false });
            waitingGif('none');
            modalStatus('', 'none', 'none');
          });
        }); /********END load */
      });
    }
  }
});

//B: BUTTON
//upload buttonftAdd
document.getElementById('upl_svg').addEventListener('change', () => {
  modalStatus('', '', 'block');
  waitingGif('block');
  //first clear the svg
  snap.clear();
  snap.remove();
  const fReader = new FileReader();
  fReader.readAsDataURL(document.getElementById('upl_svg').files[0]);
  fReader.onloadend = function (e) {
    //console.log(e.target.result);
    Snap.load(e.target.result, (frag) => {
      Snap('#svg-container').append(frag); //stage is <svg
      const snap = Snap('#stg');
      //add event lsitener for when stage is clicked
      snap.node.addEventListener('click', activeRemove);
      //attach snap obj to all elements
      const allG = snap.selectAll('.els');
      allG.forEach((el) => {
        ftAdd(el);
        if (el.freeTransform) el.freeTransform.hideHandles({ undrag: false });
      });
      //center stage
      stageCenter();
      modalStatus('', '', 'none');
      waitingGif('none');
    }); /********END load */
  };
});
