/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable default-case */
import targetEl from './objects/targetEl';
import borderProperties from './borderProperties';

export default document.addEventListener('keydown', (e) => {
  // eslint-disable-next-line prefer-const
  let { el, node, groupedEl, mceStatus } = targetEl;
  switch (e.code) {
    case 'Backspace':
      if (el != null) {
        if (mceStatus === false) {
          if (confirm('Delete Content!')) {
            if (!Object.hasOwn(el, 'freeTransform')) {
              groupedEl.freeTransform.unplug();
              groupedEl.remove();
            } else {
              targetEl.el.freeTransform.unplug();
              node.remove();
            }
            //clear targetEl obj
            el = null;
            node = null;
            //el.data('new',0);
            //show/hide border properties
            borderProperties(el);
          }
        }
      } else if (mceStatus === false) {
        alert('No item selected!');
      }
      break;
  }
});
/*end keypress********************************/
