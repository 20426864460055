/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
import obj from './objects/uiProps';

const arr = ['scale(0.25)', 'scale(0.5)', 'scale(0.75)', 'scale(1.00)'];

const stage = document.getElementById('stg');

document.getElementById('zPlus').addEventListener('mousedown', () => {
  if (obj.zoom < 3) {
    obj.zoom++;
    stage.style.transform = arr[obj.zoom];
  }
  console.log(obj.zoom);
});

document.getElementById('zMinus').addEventListener('mousedown', () => {
  if (obj.zoom > 0) {
    obj.zoom--;
    stage.style.transform = arr[obj.zoom];
  }
});
