/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
//state object
import targetEl from './objects/targetEl';
import uiProps from './objects/uiProps';

//context menu / right click
/*export const contextMenu = document.addEventListener('contextmenu', (e) => {
  e.preventDefault();
  //if outside of text block then show context menu on right click
  const el = uiProps.cMenu;
  if (targetEl.mceStatus === false) {
    targetEl.cm_x = e.pageX;
    targetEl.cm_y = e.pageY;
    el.position = 'absolute';
    el.left = `${targetEl.cm_x}px`;
    el.top = `${targetEl.cm_y}px`;
    el.opacity = 1;
    el.display = 'block';
  }
  document.addEventListener('click', (e) => {
    if (e.target.closest('.context_menu')) {
      el.opacity = 0;
      el.display = 'none';
    } else {
      el.opacity = 0;
    }
  });
});*/
