import ftAdd from './ftAdd';
import targetEl from './objects/targetEl';

export default function (e) {
  //use URL() for parcel import.meta.url
  const fo = new URL(`../foreignobj.svg`, import.meta.url);
  // eslint-disable-next-line no-undef
  const snap = Snap('#stg');
  // eslint-disable-next-line no-undef
  Snap.load(fo, (frag) => {
    snap.append(frag);
    //co-ord for text box https://stackoverflow.com/questions/3234256/find-mouse-position-relative-to-element
    const rect = snap.node.getBoundingClientRect();
    const x = e.clientX - rect.left; //x position within the element.
    const y = e.clientY - rect.top; //y position within the element.

    //select newly created el and give it a new id
    frag = snap.select('#fo_if1234');

    frag.node.children[0].classList.add(
      `els${Math.floor(Math.random() * 100000000)}`
    );
    frag.attr({ x: x, y: y });
    frag.node.removeAttribute('id');
    ftAdd(frag);

    snap.node.style.cursor = 'default';
    //targetEl.mceClass = frag.node.childNodes[1].classList[1]; //use this for npm start
    targetEl.mceClass = frag.node.firstChild.classList[1]; //use this for npm build

    frag.data('new', 1);
    //frag.data("textActive", 1);

    //add outline
    frag.node.classList.add('txt_outlined');
    frag.data('txtOutline', 1);
    snap.unmousedown();
  });
}
