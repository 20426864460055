/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
//import SVGImage from "./objects/stage";
import ftAdd from './ftAdd';

/*center the stage when document loaded into window*/
const snap = Snap('#stg');
export default () => {
  snap
    .circle(45000, 45000, 2)
    .attr({ fill: '#000000', 'fill-opacity': 0, id: 'cntr' });
  document
    .getElementById('cntr')
    .scrollIntoView({ behavior: 'instant', block: 'center', inline: 'center' });

  const el = snap.line(44271, 0, 44271, 90000).attr({
    fill: '#999999',
    stroke: '#999999',
    'stroke-width': '1px',
    id: 'rulerStage'
  });
  ftAdd(el);
  el.freeTransform?.hideHandles({ undrag: false });
};
