/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line no-undef
const snap = Snap('#stg');

export const mathReset = (el) => {
  // eslint-disable-next-line no-undef
  const obj = { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 };
  el.matrix = obj;
  el.transform().diffMatrix = obj;
  el.transform().globalMatrixel = obj;
  el.transform().localMatrixtotalMatrix = obj;
  el.node.attributes.transform.nodeValue = 'matrix(1,0,0,1,0,0)';
};

export const mathObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

export const shapeEllipse = (el, a, b, c, d, e, f) => {
  const elChild = el.children();
  const { cx, cy, rx, ry } = elChild[1].attr();
  const newcx = a * cx + c * cy + e;
  const newcy = b * cx + d * cy + f;
  const tempx1 = a * (cx - rx) + c * cy + e;
  const tempy1 = b * (cx - rx) + d * cy + f;
  const tempx2 = a * cx + c * (cy - ry) + e;
  const tempy2 = b * cx + d * (cy - ry) + f;
  const newrx = Math.sqrt(
    (newcx - tempx1) * (newcx - tempx1) + (newcy - tempy1) * (newcy - tempy1)
  );
  const newry = Math.sqrt(
    (newcx - tempx2) * (newcx - tempx2) + (newcy - tempy2) * (newcy - tempy2)
  );
  const [rotA, rotX, rotY] = [
    (Math.atan((tempy1 - newcy) / (tempx1 - newcx)) * 180) / 3.1415,
    newcx,
    newcy
  ];
  mathReset(el);
  elChild[0].attr({ cx: newcx, cy: newcy, rx: newrx, ry: newry });
  elChild[1].attr({ cx: newcx, cy: newcy, rx: newrx, ry: newry });
  el.node.attributes.transform.nodeValue = `matrix(1,0,0,1,0,0) rotate(${rotA}, ${rotX}, ${rotY})`;
  el.freeTransform.unplug();
  snap.freeTransform(el, {
    size: 3,
    rotate: true,
    keepRatio: false,
    attrs: { fill: '#ffffff', stroke: '#000000' }
  });
  el.freeTransform.showHandles();
  el.data('ftStatus', 1);
};

export const shapeRectangular = (el, a, b, c, d, e, f) => {
  const elChild = el.children();
  const { x, y, width, height } = elChild[1].attr();
  const new1x = a * x + c * y + e;
  const new1y = b * x + d * y + f;
  const new2x = a * x + a * width + c * y + e;
  const new2y = b * x + b * width + d * y + f;
  const new3x = a * x + c * y + c * height + e;
  const new3y = b * x + d * y + d * height + f;
  const newwidth = Math.sqrt(
    (new1x - new2x) * (new1x - new2x) + (new1y - new2y) * (new1y - new2y)
  );
  const newheight = Math.sqrt(
    (new1x - new3x) * (new1x - new3x) + (new1y - new3y) * (new1y - new3y)
  );
  const [rotAa, rotXx, rotYy] = [
    (Math.atan((new2y - new1y) / (new2x - new1x)) * 180) / 3.1415,
    new1x,
    new1y
  ];
  mathReset(el);
  elChild[1].attr({
    x: new1x,
    y: new1y,
    width: newwidth,
    height: newheight
  });
  elChild[0].attr({
    x: new1x,
    y: new1y,
    width: newwidth,
    height: newheight
  });
  el.node.attributes.transform.nodeValue = `matrix(1,0,0,1,0,0) rotate(${rotAa}, ${rotXx}, ${rotYy})`;
  el.freeTransform.unplug();
  snap.freeTransform(el, {
    size: 3,
    rotate: true,
    keepRatio: false,
    attrs: { fill: '#ffffff', stroke: '#000000' }
  });
  el.freeTransform.showHandles();
  el.data('ftStatus', 1);
};

export const shapeImage = (el, a, b, c, d, e, f) => {
  const { x, y, width, height } = el.attr();
  const new1x = a * x + c * y + e;
  const new1y = b * x + d * y + f;
  const new2x = a * x + a * width + c * y + e;
  const new2y = b * x + b * width + d * y + f;
  const new3x = a * x + c * y + c * height + e;
  const new3y = b * x + d * y + d * height + f;
  const newwidth = Math.sqrt(
    (new1x - new2x) * (new1x - new2x) + (new1y - new2y) * (new1y - new2y)
  );
  const newheight = Math.sqrt(
    (new1x - new3x) * (new1x - new3x) + (new1y - new3y) * (new1y - new3y)
  );
  const [rotAa, rotXx, rotYy] = [
    (Math.atan((new2y - new1y) / (new2x - new1x)) * 180) / 3.1415,
    new1x,
    new1y
  ];
  mathReset(el);
  el.attr({
    x: new1x,
    y: new1y,
    width: newwidth,
    height: newheight
  });
  el.node.attributes.transform.nodeValue = `matrix(1,0,0,1,0,0) rotate(${rotAa}, ${rotXx}, ${rotYy})`;
  el.freeTransform.unplug();
  snap.freeTransform(el, {
    size: 3,
    rotate: true,
    keepRatio: false,
    attrs: { fill: '#ffffff', stroke: '#000000' }
  });
  el.freeTransform.showHandles();
  el.data('ftStatus', 1);
};

export const shapePath = (shape, el, a, b, c, d, e, f) => {
  const elChild = el.children();
  if (elChild[1] && (shape === 'line' || elChild[1].node.nodeName === 'line')) {
    //line or g>line
    const { x1, x2, y1, y2 } = elChild[1].attr();
    const newx1 = a * x1 + c * y1 + e;
    const newy1 = b * x1 + d * y1 + f;
    const newx2 = a * x2 + c * y2 + e;
    const newy2 = b * x2 + d * y2 + f;
    elChild[1].attr({
      x1: newx1,
      x2: newx2,
      y1: newy1,
      y2: newy2
    });
    elChild[0].attr({
      x1: newx1,
      x2: newx2,
      y1: newy1,
      y2: newy2
    });
    mathReset(el);
    el.freeTransform.unplug();
    snap.freeTransform(el, {
      size: 3,
      rotate: true,
      keepRatio: false,
      attrs: { fill: '#ffffff', stroke: '#000000' }
    });
    el.freeTransform.showHandles();
    el.data('ftStatus', 1);
  } else if (elChild[1]) {
    //path or g>path
    let [mx, my, Sstr, qx1, qy1, qx2, qy2] = elChild[1].node.attributes.d.value.split(' ');
    const Fstr = mx.substr(0, 1);
    mx = mx.substr(1);
    //console.log(Fstr,mx,my,Sstr,qx1,qy1,qx2,qy2);
    const newMx = a * mx + c * my + e;
    const newMy = b * mx + d * my + f;
    let newQx1 = 0;
    let newQy1 = 0;
    let newQx2 = 0;
    let newQy2 = 0;
    if (Sstr === 'Q') {
      ////  Q - Capital letters means absolutely positioned
      newQx1 = a * qx1 + c * qy1 + e;
      newQy1 = b * qx1 + d * qy1 + f;
      newQx2 = a * qx2 + c * qy2 + e;
      newQy2 = b * qx2 + d * qy2 + f;
    } else {
      ////// q- lower cases means relatively positioned.
      newQx1 = a * qx1 + c * qy1;
      newQy1 = b * qx1 + d * qy1;
      newQx2 = a * qx2 + c * qy2;
      newQy2 = b * qx2 + d * qy2;
    }
    elChild[0].node.attributes.d.value = `${Fstr}${newMx} ${newMy} ${Sstr} ${newQx1} ${newQy1} ${newQx2} ${newQy2}`;
    elChild[1].node.attributes.d.value = `${Fstr}${newMx} ${newMy} ${Sstr} ${newQx1} ${newQy1} ${newQx2} ${newQy2}`;
    mathReset(el);
    el.freeTransform.unplug();
    snap.freeTransform(el, {
      size: 3,
      rotate: true,
      keepRatio: false,
      attrs: { fill: '#ffffff', stroke: '#000000' }
    });
    el.freeTransform.showHandles();
    el.data('ftStatus', 1);
  } else {
    console.log('STILL DO: curly bracket resize handles rewrite to matrix');
  }
};
